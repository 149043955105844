import React from "react"
import GlobalNavi from "../../components/GlobalNavi"
import { Box } from "@material-ui/core"
import TopSlider from "../../components/TopSlider"
import SEO from "../../components/seo"
import LinkButton from "../../components/LinkButton"
import DocLayout from "../../components/DocLayout"
import BpHr from "../../components/BpHr"

export default function Home() {
  return (
    <div>
      <SEO title={`Home`} lang={`en`} />
      <GlobalNavi lang={`en`} />

      <div className={`l-wrapper`}>
        <Box mb={4} pt={`60px`}>
          <h1 className={`fontLvH3`}>
            Yokohama Paratriennale 2020 <br />
            <i>In Time You’ll Be Heard</i>
          </h1>
        </Box>

        <Box mb={4}>
          <TopSlider play={true} />
        </Box>

        <div className={`l-wrapper__inner`}>
          <Box mb={`40px`}>
            <p className={`fontLvBody`}>
              <i>In Time You’ll Be Heard</i> is the art exhibition component of
              the Yokohama Paratriennale 2020, which was made as a book form.
            </p>
          </Box>

          <Box mb={`40px`}>
            <h2 className={`fontLvH2`}>
              Free download <i>In Time You’ll Be Heard</i> (PDF)
            </h2>
            <LinkButton
              color={`green`}
              target={`_blank`}
              to={"/assets/data/yokopara2020_book.pdf"}
            >
              Download Book PDF
            </LinkButton>
          </Box>

          <Box mb={`40px`}>
            <DocLayout>
              <h2>Biographies of artists and presenters</h2>

              <Box
                mt={{ xs: `30px`, sm: `60px` }}
                mb={{ xs: `20px`, sm: `40px` }}
              >
                <h2 data-custom-type={`small`}>Participating artists</h2>
              </Box>

              <Box>
                <h3>Naoto Iguchi</h3>
                <p>
                  Born in 1971 in Mie Prefecture. He lives in Nagoya City and
                  has been a member of Safran Seikatsu En since 1987. Using a
                  convenience store in town and a copy machine at the welfare
                  facility, he scans his own face and the things he likes at the
                  time, as part of his daily routine. By pressing his face
                  against the glass surface and operating the buttons by
                  himself, he creates a unique distortion by moving his body
                  with the movement of the sensor light. These actions were born
                  out of playfulness with a welfare facility staff and became
                  habitual. He has been going to the same convenience store for
                  about 15 years, and when he does, the store staff deftly wipes
                  the grease from his face off the glass surface.
                </p>
              </Box>
              <BpHr type={`dot`} />
              <Box>
                <h3>Kazumi Kamae</h3>
                <p>
                  Born in 1966, she lives in Shiga Prefecture and has been a
                  member of Yamanami Kobo since 1985. She is not good at
                  communicating her feelings to people, so she continues to make
                  sculptures of a person that she wants to look at her as a
                  communication tool. Her sculpture model is just the person she
                  loves. After deciding on the subject matter and preparing the
                  original shape, she painstakingly fills the entire surface
                  with fine-grained ceramic clay. It can take more than two
                  months to complete a large artwork, and the countless grains
                  covering the entire surface of the work transform it into
                  various shapes. She just wants the man she loves to approve of
                  her work—to this day, this desire is all she has in mind as
                  she heads into each creation.
                </p>
              </Box>
              <BpHr type={`dot`} />
              <Box>
                <h3>Yuki Kawado</h3>
                <p>
                  Born in 1984, she lives in Yokohama. She has been a member of
                  Art Maple Kalen in Kohoku-ku, Yokohama since 2003. She has
                  been creating paintings and embroidery of characters from
                  Disney and children’s TV shows, as well as landscapes, food,
                  etc. Many of them are created in succession, using similar
                  objects and compositions. For some time now, she has been
                  producing video works by making time-lapse films while moving
                  puppets and stages drawn on paper.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Atsushi Sugiura</h3>
                <p>
                  Born in 1970, he lives in Saitama Prefecture. He has been a
                  member of the Social Welfare Service Corporation Minuma
                  Fukushi Kai Kobo Shu. When he relaxes in his daily life, such
                  as after dinner or when he can take a break, he holds a box of
                  photographs and enjoys looking at them, sometimes lying in
                  bed. After years of touching them, the photos have taken on
                  various forms. He has participated in This is Amazing! (Museum
                  of Modern Art, Saitama, 2015), Art Brut from Japan, Another
                  Look (Collection de l’art brut, 2018), and many other
                  exhibitions.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Jess Thom</h3>
                <p>
                  Jess Thom is a writer and artist. She cofounded Touretteshero
                  in 2010 to creatively sublimate her own Tourette’s syndrome.
                  Her theatrical works Backstage in Biscuit Land, and Not I,
                  based on Samuel Beckett’s theater of the absurd, have been
                  performed at the Unlimited Festival and the Edinburgh Fringe
                  to critical acclaim. She is also active in the community,
                  including organizing participatory events for children with
                  Tourette’s syndrome. She is the author of Welcome to Biscuit
                  Land: A Year in the Life of Touretteshero (2012).
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Takayuki Yamamoto</h3>
                <p>
                  Born in 1974 in Aichi Prefecture, Japan. Based on his
                  experience as an elementary school teacher, he has chosen
                  “education” as one of his central themes and creates works
                  based on children’s workshops. Through creative sensibilities
                  hidden in conversation and play, he depicts the peculiarities
                  of systems and customs that usually go unnoticed and the
                  relationship between individuals and society. In recent years,
                  he has been working on a number of projects in collaboration
                  with local communities. Major exhibitions include Go Betweens:
                  The World Through Children (Mori Art Museum and others,
                  2014–2015), the Kochi-Muziris Biennale (India, 2016), and
                  Asian Art Award 2017 (TERRADA Art Complex, 2017). His most
                  recent piece of writing is Kamagaminoiware in Art and Labor,
                  edited by Masao Shirakawa and Atsushi Sugita (Suiseisha 2018).
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Li Binyuan</h3>
                <p>
                  Li Binyuan was born in 1985 in Yongzhou, China. He graduated
                  from the Sculpture Department of the Central Academy of Fine
                  Arts in 2011. He currently lives and works in Beijing. Li
                  Binyuan creates performance art with a sculptural mind, much
                  of which is presented in the form of performance videos.
                  Through his personal interventions, Li invites viewers to
                  reconsider established/conventional boundaries, casting doubt
                  on common sense as a medium of control. His recent major
                  exhibitions include Land: Zhang Huan and Li Binyuan (MoMA PS1,
                  New York, USA, 2018) and Li Binyuan (HOW Art Museum, Shanghai,
                  China, 2019).
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>
                  Isogo-ku Shogaisha Chiiki Katsudo Home + Satoshi Iitsuka
                </h3>
                <p>
                  The Isogo-ku Shogaisha Chiiki Katsudo Home is a community for
                  people with various disabilities in Isogo-ku, Yokohama. Recent
                  years’ activities include the Yokohama Art Site project
                  “Greetings Series, Vol. 2: My Normal, Your Normal”
                  (https://y-artsite.org), co-planned and co-produced with
                  Satoshi Iitsuka. Satoshi Iitsuka is a filmmaker. After working
                  for an independent film production company, he became a
                  freelance director. He has produced a wide range of films,
                  mainly for TV. In recent years, he has been involved in
                  independent productions of documentary films and in planning
                  and running projects in collaboration with the Isogo-ku
                  Shogaisha Chiiki Katsudo Home. He is also active as a member
                  of Backers (Dengeki Shogaisha Shohin Kikaku Kaigi).
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Mari Kashiwagi</h3>
                <p>
                  Poet. Born in Germany. Winner of the 33rd Gendai Shi Techo
                  Award. Her recent publications include a book of poetry in
                  Japanese and English entitled Butterflies (Shichosha), and an
                  art book entitled Motto Shiritai Yakimono (Pottery That You
                  Want to Know More About) (Tokyo Bijutsu). Her other books of
                  poetry include Nectar’s root as far as its Resonance reaches
                  and Music, of Days. She has exhibited and read poems at the
                  Aomori Contemporary Art Center, Morioka Shoten, and others. As
                  a curator, she worked for Idemitsu Art Museum until 2019,
                  curated exhibitions on ceramics. She has been invited to the
                  Struga International Poetry Festival, the Princeton Festival,
                  and others. Her poems have been translated into several
                  languages.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Kasetsu</h3>
                <p>
                  Born in 1975 in Kyoto, Japan. Calligrapher. Graduated from the
                  Psychology Department of the Faculty of Letters of Ritsumeikan
                  University. Based on detailed research into the origins of
                  characters, she has been creating works that express the
                  intersection of contemporary phenomena and a single Chinese
                  character, as well as holding workshops in Japan and abroad on
                  the theme of exploring the possibilities of expression through
                  the use of characters. She has published ATO 跡 (Marks)
                  (Between the Books), Sho No Sumika (Calligraphic Dwelling
                  Place) (Akaaka), and others. She has also worked on a variety
                  of book title calligraphy, including the collection Senso x
                  Bungaku (War x Literature) (Shueisha) and Ishihara Shin’taro
                  no Bungaku (The Literature of Ishihara Shin’taro)(Bungei
                  Shunju).
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Teppei Kaneuji</h3>
                <p>
                  Kaneuji creates his works using a collage-like technique that
                  reinterprets existing contexts by cutting out and joining
                  parts of objects around us. He has staged solo exhibitions at
                  the Yokohama Museum of Art (2009), the Ullens Center for
                  Contemporary Art, Beijing (2013), the Marugame Genichiro
                  Inokuma Museum of Contemporary Art (2016), and others and has
                  displayed his work in international exhibitions in Japan and
                  abroad. Since 2011, he has also been involved in stage design,
                  and in recent years, he has been creating stage works.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>dj sniff</h3>
                <p>
                  dj sniff (Takuro Mizuta Lippit) is a musician, curator and
                  producer in the field of experimental electronic arts and
                  improvised music. His musical work builds upon a distinct
                  practice that combines DJing, instrument design and free
                  improvisation. Between 2007-2012, he was Artistic Director of
                  STEIM, Studio of Electro-Instrumental Music Amsterdam.
                  Currently, he is Co-Director of AMF – A collaborative platform
                  and festival for experimental musicians in Asia.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Mieko Nakagawa</h3>
                <p>
                  Born in Saitama Prefecture. She graduated from Tsuda
                  University in 2018 with a degree in International Relations
                  and completed a master’s program at the Tokyo University of
                  Foreign Studies in 2020. Since July 2017, she has been working
                  as a staff member of theVerbal Imaging Museum Tour with
                  Visually Impaired People, holding workshops on the theme of
                  “seeing” at museums in the Tokyo Metropolitan Area.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Masayo Funakoshi</h3>
                <p>
                  After majoring in sculpture at the Pratt Institute, she
                  discovered her potential for expression through cooking. A
                  graduate of the Institute of Culinary Education, she worked at
                  Blue Hill and other restaurants in New York before traveling
                  in Europe and Asia. She toured the Pacific Ocean as a chef on
                  an Australian-registered cruise ship and worked at the
                  renowned Tandjung Sari Hotel in Bali before moving her base to
                  Kyoto in 2012, where she has been delving into the local area
                  from the perspectives of food culture, cultural anthropology,
                  and history and expressing them through food, both in Japan
                  and abroad. In 2018, she opened the restaurant Farmoon. Her
                  recent activities include serving as director of the Food
                  Department of Nara City, East Asian Cultural City 2016, and
                  organizer of the Nara Food Caravan. She also produced the film
                  An Empty Vessel (director: Hiroo Ninomiya; photography: Masato
                  Indo).
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Mieko Matsumoto</h3>
                <p>
                  Photographer and artist. Her work is mediated by photography,
                  video, and text. Major exhibitions include Criterium 68 at Art
                  Tower Mito (2006), Kenpoku Art 2016 (2016), Ichihara Art x Mix
                  (2014), and The Second Stage at GG#46 at Guardian Garden
                  (2017). She is the author of a book of photography and poetry
                  entitled Ikiru (coauthored by Shuntaro Tanigawa, Nanarokusha,
                  2008) and other publications. In addition to Kiwamari-so in
                  Mito, she runs Maison Kenpoku, which is based on research and
                  projects to open up spaces in the community.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Yuichi Yokoyama</h3>
                <p>
                  Born in 1967 in Miyazaki Prefecture. He is a manga artist and
                  painter. He studied oil painting at Musashino Art University,
                  but since 2000, he has chosen manga as a method of expression
                  that allows him to draw time. His manga works include New
                  Engineering, Travel, NIWA, Baby Boom, The Room of the World
                  Map, Iceland, and Plaza, many of which have been translated
                  and published in the USA, France, Italy, Spain, Russia, and
                  other countries. He also produces paintings and has held solo
                  and group exhibitions in Japan and abroad.{" "}
                </p>
              </Box>

              <Box mt={`60px`} mb={`40px`}>
                <h2 data-custom-type={`small`}>
                  Oshaberi Taiwa Kansho presenters
                </h2>
              </Box>

              <Box>
                <h3>Robert Campbell</h3>
                <p>
                  Robert Campbell, Ph.D., is a scholar of Japanese literature
                  and the Director-General of the National Institute of Japanese
                  Literature (NIJL), Tokyo. Born in New York City, he studied in
                  the Departments of Economics and Oriental Languages,
                  University of California, Berkeley (B.A. 1981), and the
                  Department of East Asian Languages and Civilizations, Graduate
                  School of Fine Arts, Harvard University (M.A. 1984; Ph.D.
                  1992). Campbell moved to Japan to study Edo literature as a
                  research student in the Department of Japanese Language and
                  Literature, Kyushu University, Fukuoka City (1985), joining
                  that department as assistant professor (1987). He then moved
                  on to the National Institute of Japanese Literature, Tokyo
                  (associate professor, 1995), and relocated to the University
                  of Tokyo in 2000, where he taught as professor from 2007. His
                  tenure as Director-General of NIJL began in 2017. Robert
                  Campbell’s research centers on the sinological literature,
                  art, media and intellectual discourses of late Edo and early
                  Meiji period Japan. Besides editing and contributing to
                  numerous volumes on Japanese literature, art and drama, he is
                  active in the Japanese media as television host, news
                  commentator, newspaper columnist, book reviewer and radio
                  personality.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Mieko Nakagawa</h3>
                <p>
                  Born in Saitama Prefecture. She graduated from Tsuda
                  University in 2018 with a degree in International Relations
                  and completed a master’s program at the Tokyo University of
                  Foreign Studies in 2020. Since July 2017, she has been working
                  as a staff member of theVerbal Imaging Museum Tour with
                  Visually Impaired People, holding workshops on the theme of
                  “seeing” at museums in the Tokyo Metropolitan Area.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Tomoe Shinohara</h3>
                <p>
                  Designer / Artist. Graduated from the fashion design course of
                  the junior college department at Bunka Fashion College
                  (currently Bunka Gakuen University). Shinohara made her debut
                  as a singer with Sony Music Records in 1995 and has built a
                  prolific career as a TV, movie and stage actress. She has also
                  taken on various roles in TV commercials and programs as a
                  narrator / voice actress. She has also been working as a
                  costume designer for others and has done stage costumes for
                  Japanese artists such as Yumi Matsutoya (singer) and Arashi
                  (idol group) for their concert tours. She has worked with
                  various renowned companies to launch collaborative products as
                  an illustrator. In 2020, she founded the creative studio,
                  STUDEO with an art director Tatsuki Ikezawa. At "SHIKAKU
                  -pictures transforming costumes-", held in July 2020, she
                  challenged the issue of sustainability and exhibited costume
                  works that used up all the surplus fabric without waste.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Katsuhiro Nishioka</h3>
                <p>
                  He has been involved in creating spaces in cultural and other
                  facilities where diverse people get together. Through the
                  activities of the Universal Museum and diversity-related
                  training programs, he continues to enjoy art appreciation
                  while emphasizing the importance of "experiencing and thinking
                  together with various people.”
                </p>
              </Box>

              <Box mb={`100px`}>
                <h3>Kasetsu</h3>
                <p>
                  Born in 1975 in Kyoto, Japan. Calligrapher. Graduated from the
                  Psychology Department of the Faculty of Letters of Ritsumeikan
                  University. Based on detailed research into the origins of
                  characters, she has been creating works that express the
                  intersection of contemporary phenomena and a single Chinese
                  character, as well as holding workshops in Japan and abroad on
                  the theme of exploring the possibilities of expression through
                  the use of characters. She has published ATO 跡 (Marks)
                  (Between the Books), Sho No Sumika (Calligraphic Dwelling
                  Place) (Akaaka), and others. She has also worked on a variety
                  of book title calligraphy, including the collection Senso x
                  Bungaku (War x Literature) (Shueisha) and Ishihara Shin’taro
                  no Bungaku (The Literature of Ishihara Shin’taro)(Bungei
                  Shunju).
                </p>
              </Box>
              <BpHr type={`dot`} />
            </DocLayout>
          </Box>

          <Box mb={`40px`}>
            <DocLayout>
              <Box
                mt={{ xs: `30px`, sm: `60px` }}
                mb={{ xs: `20px`, sm: `40px` }}
              >
                <h2 data-custom-type={`small`}>Curators</h2>
              </Box>
              <Box>
                <h3>Kodama Kanazawa</h3>
                <p>
                  Born in 1973 in Kanagawa. After working for 12 years in public
                  art museums such as Contemporary Art Museum, Kumamoto, she
                  began working independently in 2013. Her interest lies in
                  human being’s psychological transition in conjunction with the
                  change of time, which also relates to her theme: How can we
                  recognize invisible social structures that result in
                  difficulties such as poverty, disparity or discrimination? In
                  practice, her interest manifests in the topics of Japanese
                  modern and contemporary history, globalization and new-media
                  art from the world, which have been expressed in numerous
                  exhibitions, both domestic and overseas, during her career.
                  Her recent curatorial practices include AKI INOMATA:
                  Significant Otherness (2019), Stranger Than Fiction (2019),
                  Yuko Mohri: Assume That There Is Friction and Resistance
                  (2018) (Those three shows were held in Towada Art Center),
                  Hangzhou Fiber Art Triennial (Zhejiang Art Museum etc. China,
                  2019) and Enfance (Palais de Tokyo, France, 2018).
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Miyuki Tanaka</h3>
                <p>
                  Born in 1980 in Osaka. Curator at Tokyo Shibuya Koen-dori
                  Gallery. After working at art centers and other institutions,
                  she started working on projects that defy categorization under
                  the theme of “disability is a perspective that redefines the
                  world.” Through projects dealing with expressions that have
                  yet to be evaluated and valued, she rethinks the way we view
                  and perceive expressions together with people with
                  disabilities and viewers. Recent projects include the
                  exhibitions The Great Everyday Life (NO-MA, 2017) and Dance
                  Work in Progress (KAAT Kanagawa Arts Theatre, 2017–2019), the
                  film Night Cruising (2019), and the game Audio Game Center
                  (2017–present). She has been a speaker at conferences on
                  disability and diversity, including DO-IT of the University of
                  Tokyo and Rare with Google, and has been a part-time lecturer
                  at the School of Liberal Arts Research and Education of the
                  Tokyo Institute of Technology since 2018. She is a doctoral
                  student at the Faculty of Arts and Letters of Waseda
                  University.
                </p>
              </Box>
              <BpHr type={`dot`} />

              <Box>
                <h3>Megumi Hadai</h3>
                <p>
                  Megumi Hatai is the current Curator of Contemporary Art at
                  Chiba City Museum of Art. A native of Wakayama Prefecture,
                  Hatai decided to leave high school to become a patissier. She
                  studied at the Tsuji Institute of Patisserie and Centre de
                  Perfectionnement Ecole Hôtelière Tsuji, Château de I'Eclair.
                  She then went on to train at Chocolaterie Béline (Le Mans,
                  France), and later worked for two years at Charles Friedel
                  (Osaka). After completing her General Education Certificate,
                  Hatai resumed her academic studies at Osaka University Faculty
                  of Letters, where she majored in modern Western art history.
                  She received her B.A. and M.A. from Osaka University, and is
                  currently A.B.D. in the Doctoral Course for the History of
                  Western Art. After working as a curator at Marugame
                  Genichiro-Inokuma Museum of Contemporary Art, Hatai assumed
                  her current position in 2015. She is responsible for the
                  exhibition and collection of contemporary art as well as
                  museum education. Some of her recent work includes the
                  exhibition 目［mé］: Obviously, no one can make heads nor
                  tails (2019) and Shinji Ogawa: The Whole World Without You
                  (2016). She is currently overseeing the planning for a new
                  series of programming on the occasion of the Chiba City Museum
                  of Art’s renewal and expansion. This “Art Lab” project offers
                  visitors a workshop space to participate in making of
                  contemporary artworks.
                </p>
              </Box>

              <Box
                mt={{ xs: `30px`, sm: `60px` }}
                mb={{ xs: `20px`, sm: `40px` }}
              >
                <h2 data-custom-type={`small`}>Team Credit</h2>
              </Box>
              <Box mb={`100px`}>
                <p>
                  Direction & Production: Shunya Hagiwara <br />
                  Cording: Kyoko Wada
                  <br />
                  Art direction: Natsuko Yoneyama
                  <br />
                  Movie direction: Shintaro Tamada
                  <br />
                  Sign language interpretation: Yuko Setoguchi, Misa Wada (The
                  Project to Make Museums Accessible for the Deaf and Hard of
                  Hearing)
                </p>
              </Box>
            </DocLayout>
          </Box>
        </div>
      </div>
    </div>
  )
}
