import React from "react"
import { StaticQuery, graphql } from "gatsby"
import LogoBookProject from "../images/logo/logo-book-project.svg"
import LogoPattern1 from "../images/logo/logo-pattern-1.svg"
import LogoPattern2 from "../images/logo/logo-pattern-2.svg"
import LogoPattern3 from "../images/logo/logo-pattern-3.svg"
import LogoPattern4 from "../images/logo/logo-pattern-4.svg"
import LogoOnline from "../images/logo/logo-online.svg"
import css from "./TopSlider.module.scss"
import HgwSlider from "./HgwSlider"

const TopSlider = props => {
  const logoPatterns = [LogoPattern1, LogoPattern2, LogoPattern3, LogoPattern4]
  const logoPattern =
    logoPatterns[Math.floor(Math.random() * logoPatterns.length)]
  const rotateOnline = Math.floor(Math.random() * 40) - 20
  const rotateBookProject = Math.floor(Math.random() * 40) - 20

  const classShowHide = props.play ? css.show : css.hide

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={css.base + " " + classShowHide}>
          <div className={css.base__Slider}>
            <HgwSlider>
              {data.allFile.nodes.map((image, key) => {
                return (
                  <div key={key} className={css.slideCustom}>
                    <img src={image.childImageSharp.fixed.src} alt={""} />
                  </div>
                )
              })}
            </HgwSlider>
          </div>

          <figure className={css.logoBookProject}>
            <img
              src={LogoBookProject}
              alt={``}
              style={{ transform: `rotate(${rotateBookProject}deg)` }}
            />
          </figure>
          <figure className={css.logoOnline}>
            <img
              style={{ transform: `rotate(${rotateOnline}deg)` }}
              src={LogoOnline}
              alt={``}
            />
          </figure>
          <figure className={css.logoPattern}>
            <img src={logoPattern} alt={``} />
          </figure>
        </div>
      )}
    />
  )
}

export default TopSlider

const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/home/home.+jpg/" } }) {
      nodes {
        childImageSharp {
          fixed(width: 980) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`
